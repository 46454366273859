import fetch from 'isomorphic-unfetch'
import localStorage from 'localStorage'
import Router from 'next/router'

const API_ROOT_REMOTE = process.env.NEXT_PUBLIC_API_URL
// const API_ROOT_REMOTE = 'https://api.gd.lingti.com'
const API_GO_ROOT_REMOTE = process.env.NEXT_PUBLIC_GO_API_URL

const DEVICE_TYPE = 'WEB_LINGTI'
export const setToken = (s) => {
  return localStorage.setItem(`Token`, s)
}
export const getToken = () => {
  return localStorage.getItem('Token')
}

export const clearToken = () => {
  return localStorage.removeItem('Token')
}

const headers = () => {
  return {
    // 'Access-Control-Allow-Origin': '*',
    Authorization: getToken(),
    // 'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PUT, PATCH, DELETE',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
}

const body = (data) => {
  return {
    DEVICE_TYPE,
    ...data,
  }
}

const parseResponse = async (res) => {
  if (res.headers.get('Authorization')) {
    setToken(res.headers.get('Authorization'))
  }
  try {
    const json = await res.json()
    if (res.status >= 400) {
      json.status = res.status
    }
    if (json.error === 'revoked token' || res.status === 401) {
      clearToken()
      if (!Router.asPath.startsWith('/login')) {
        Router.push({ pathname: '/login', query: { redirect: Router.asPath } })
      }
    }
    return json
  } catch (error) {
    console.error(error)
  }
  return false
}

export const get = async (path, data = {}, isAbsolute = false) => {
  const params = body(data)
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')
  const remote = path.startsWith('machines') ? API_GO_ROOT_REMOTE : API_ROOT_REMOTE
  const url = isAbsolute ? `${path}?${query}` : `${remote}/${path}?${query}`
  const res = await fetch(url, {
    method: 'GET',
    headers: headers(),
  })
  const content = await parseResponse(res)
  return content
}

const requestMethod =
  (method) =>
  async (path, data, isAbsolute = false) => {
    const url = isAbsolute ? path : `${API_ROOT_REMOTE}/${path}`
    const res = await fetch(url, {
      method,
      headers: headers(),
      body: JSON.stringify(body(data)),
    })
    const content = await parseResponse(res)
    return content
  }

const requestStreamMethod =
  (method) =>
  async (path, data, onReceiveData, setLoading, isAbsolute = false) => {
    const url = isAbsolute ? path : `${API_ROOT_REMOTE}/${path}`
    const res = await fetch(url, {
      method,
      headers: headers(),
      body: JSON.stringify(body(data)),
    })

    if (res.headers.get('Authorization')) {
      setToken(res.headers.get('Authorization'))
    }

    const reader = res.body.getReader()
    const decoder = new TextDecoder()
    let result = ''
    let isFirstUpdate = true

    const readStream = async () => {
      const { done, value } = await reader.read()
      if (done) return // 读取完成
      result += decoder.decode(value, { stream: true })
      if (isFirstUpdate) {
        setLoading(false)
        isFirstUpdate = false
      }

      try {
        // 尝试解析 JSON
        while (true) {
          const endIndex = result.indexOf('}\n') // 假设每个 JSON 对象以 '}' 结束并换行
          if (endIndex !== -1) {
            const jsonString = result.slice(0, endIndex + 1) // 提取完整的 JSON 字符串
            const jsonData = JSON.parse(jsonString) // 解析 JSON
            onReceiveData(jsonData.data) // 处理接收到的数据
            result = result.slice(endIndex + 1) // 更新 result，去除已解析部分
          } else {
            break // 没有找到完整的 JSON，退出循环
          }
        }
      } catch (error) {
        console.error('解析错误:', error)
      }

      await readStream() // 递归调用
    }

    try {
      await readStream() // 启动流读取
      return JSON.parse(result) // 返回JSON
    } catch (error) {
      console.error('流处理错误:', error)
    }

    return false
  }

export const post = requestMethod('POST')
export const put = requestMethod('PUT')
export const httpDelete = requestMethod('DELETE')
export const postStream = requestStreamMethod('POST')
